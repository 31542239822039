import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { TableReference } from 'state-domains/domain';

import { buildAddTableToTableViewUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const addTableToTableView = (
    tableViewId: string,
    payload: any,
    tableId = '',
): Observable<TableReference> => {
    const url = buildAddTableToTableViewUrl(tableViewId, tableId);
    return sendRequestWithXSRFToken(url, payload, tableId ? 'PUT' : 'POST').pipe(
        switchMap(({ response }: AjaxResponse<TableReference>) => {
            const tableObject = convertToCamel<TableReference>(response);
            return observableOf(tableObject);
        }),
    );
};
